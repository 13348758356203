.otpInput {
  width: 50px !important;
}

@media only screen and (max-width: 450px) {
  .otpInput {
    width: 35px !important;
    height: 35px !important;
    font-size: 13px;
    background-color: transparent !important;
    padding: 0 !important;
  }
}
